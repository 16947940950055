import { useRef } from 'react';
import { PassengerRuleFragment } from '@codegen/cmsUtils';
import useOnOutsideClick from '@ui/hooks/useOnOutsideClick';
import Box from '@ui-v2/core/Box/Box';
import Input from '@ui-v2/core/Forms/Input/Input';
import Menu from '@ui-v2/core/Menu/Menu';
import useCmsTranslation from '@utils/hooks/useCmsTranslation';
import {
  isDecrementPassengerDisabled,
  isIncrementPassengerDisabled,
} from './helpers';
import PassengerCounterItem from './PassengerCounterItem';
import { usePassengerCounter } from './usePassengerCounter';

const PassengerCounter = () => {
  const {
    handleChangePassengerAge,
    handleInputFocus,
    handleSetPassengers,
    handleSetSearchState,
    numberOfSelectedPassengers,
    openState,
    passengerRules,
    passengers,
    searchState,
  } = usePassengerCounter();

  const pickerRef = useRef(null);

  useOnOutsideClick(pickerRef, () => {
    if (searchState === 'selecting-passengers') {
      handleSetSearchState('idle');
    }
  });

  const { t } = useCmsTranslation();

  const handleDecrement = (paxValue: number[], type: string) => {
    if (paxValue.length > 0) {
      handleSetPassengers({
        ...passengers,
        [type]: paxValue.slice(0, -1),
      });
    }
  };

  const handleIncrement = (paxRule: PassengerRuleFragment, type: string) => {
    if (
      passengerRules &&
      numberOfSelectedPassengers < (passengerRules.maxCount ?? Infinity)
    ) {
      handleSetPassengers({
        ...passengers,
        [type]: [
          ...(passengers[type] || []),
          paxRule.maxAge ?? paxRule.minAge ?? 0,
        ],
      });
    }
  };

  const getPaxRuleSubtitle = (paxRule: PassengerRuleFragment) => {
    if (paxRule.minAge !== undefined && paxRule.minAge !== null) {
      return `${paxRule.minAge}${paxRule.minAge > 15 ? '+' : ''}${
        paxRule.maxAge ? ` - ${paxRule.maxAge}` : ''
      }`;
    }

    return '';
  };

  return (
    <Box position="relative">
      <Input
        data-cy="passenger-input"
        iconLeft="passengerIcon"
        id="passengers-input-desktop"
        label={t('Passengers', 'Passengers')}
        name="passengers"
        onFocus={handleInputFocus}
        overriddenActiveState={
          searchState === 'selecting-passengers' || openState === 'opened'
        }
        overriddenFocusState={searchState === 'selecting-passengers'}
        placeholder={`1 ${t('Passenger', 'Passenger').toLocaleLowerCase()}`}
        readOnly
        value={
          searchState === 'selecting-passengers' || openState === 'opened'
            ? `${numberOfSelectedPassengers} ${
                numberOfSelectedPassengers > 1
                  ? t('Passengers', 'Passengers').toLocaleLowerCase()
                  : t('Passenger', 'Passenger').toLocaleLowerCase()
              }`
            : ''
        }
        variant="floating-label"
      />
      {searchState === 'selecting-passengers' && (
        <Menu
          id="passenger-counter-menu"
          maxHeight={480}
          ref={pickerRef}
          widthCustom={320}
        >
          {passengerRules?.rules.map((paxRule, index) => {
            const type = paxRule.passengerType;
            const paxValue = passengers[type] ?? [];

            return (
              <PassengerCounterItem
                isDecrementDisabled={isDecrementPassengerDisabled({
                  maxPassengersAllowed: passengerRules.maxCount ?? Infinity,
                  numberOfSelectedPassengersOfType: paxValue.length,
                  numberOfTotalSelectedPassengers: numberOfSelectedPassengers,
                  passengers,
                  paxRule,
                  passengerRules,
                })}
                isIncrementDisabled={isIncrementPassengerDisabled({
                  maxPassengersAllowed: passengerRules.maxCount ?? Infinity,
                  numberOfSelectedPassengersOfType: paxValue.length,
                  numberOfTotalSelectedPassengers: numberOfSelectedPassengers,
                  passengers,
                  paxRule,
                })}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onChangePaxAge={handleChangePassengerAge}
                onDecrement={() => handleDecrement(paxValue, type)}
                onIncrement={() => handleIncrement(paxRule, type)}
                rule={paxRule}
                subtitle={getPaxRuleSubtitle(paxRule)}
                title={paxRule.label.value}
                value={paxValue}
              />
            );
          })}
        </Menu>
      )}
    </Box>
  );
};

export default PassengerCounter;
